import "../styles/style.css"
import "../styles/bootstrap.min.css"

export default function Whatsappflot() {
    return (
        <div className="whatsapp-btn">            
            <a href="https://api.whatsapp.com/send?phone=919054017231&amp;text=Hello%20Safalya%20ENT%20%26%20Skull%20Base%20Hospital,%20I%20have%20an%20query.%20Please%20call%20me%20back!" target="_blank">                    
				<div className="circle-wave">
					<img src={require("../img/whatsapp-icon.png")} width="50px" height="50px" alt="" title="Whatsapp"/>                        
				</div>
			</a>            
        </div>
    )
}