import "react-toastify/dist/ReactToastify.css";
import { useRef, useState } from 'react'
import Topbar from '../components/Topbar.jsx'
import SafalyaNavbar from '../components/SafalyaNavbar.jsx'
import Whatsappflot from '../components/Whatsappflot.jsx'
import EventFAB from '../components/EventFAB.jsx'
import Footer from '../components/Footer.jsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons'
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function Contact() {  
    const form = useRef();
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();

    // Validation Logic begin

    const validateForm = () => {
        const f = form.current;
        let hasError = true;
        let errors = []
        
        // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if(f.elements.name.value == ""){
            hasError = false;    
            errors.push("Please provide your name");  
        }
        if(f.elements.email.value== ""){
            hasError = false;
            errors.push("Please provide valid email address");  
        }
        if(f.elements.message.value==""){
            hasError = false;        
            errors.push("Please write some message");  
        }

        if(errors.length>0){
            let errorString = "";
            errors.forEach(element => {
                errorString += '\n# ' + element
            });
            alert(errorString);
        }                

        return hasError;        
    };

    // Validation Logic end
    
    const sendEmail = (e) => {
        e.preventDefault(); // prevents the page from reloading when you hit “Send”

        if(validateForm()){
            setLoading(true);            

            emailjs.sendForm('service_0rtylpb', 'template_prpuuyb', form.current, 'j-WhXUVtZVvN66K6Y')
                .then((result) => {
                    form.current.reset();
                    setLoading(false);
                    navigate("/contact/thankU", {text: "Your message has been sent successfully!"})
                }, (error) => {
                    setLoading(false);
                    // toast.error(error.text);
                    alert("Something went wrong, try after some time or use WhatsApp appointment");
                });
        }        
    };
    
    return (
        <main>
            <Topbar/>
            <SafalyaNavbar/>  
            <div className="container-fluid pt-5">
                <div className="container">
                    <div className="text-center mx-auto mb-5" style={{maxWidth: "500px"}}>
                        <h3 className="d-inline-block color-secondary-dark text-uppercase  border-5">Any Questions?</h3>
                        <h2 className="color-primary-dark display-4">Please Feel Free To Contact Us</h2>
                    </div>
                    <div className="row g-5 mb-5">
                        <div className="col-lg-4">
                            <div className="bg-primary-light d-flex flex-column align-items-center justify-content-center text-center" style={{height: "200px"}}>
                                <div className="d-flex align-items-center justify-content-center bg-secondary-light rounded-circle mb-4" style={{width: '100px', height: '70px', transform: 'rotate(-15deg)'}}>
                                    <FontAwesomeIcon className="color-secondary-dark" style={{transform: "rotate(15deg)"}} icon={faPhone} size="2x"/>
                                </div>
                                <h6 className="mb-0">+91-8849363677</h6>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="bg-primary-light d-flex flex-column align-items-center justify-content-center text-center" style={{height: "200px"}}>
                                <div className="d-flex align-items-center justify-content-center bg-secondary-light rounded-circle mb-4" style={{width: '100px', height: '70px', transform: 'rotate(-15deg)'}}>
                                    <FontAwesomeIcon className="color-secondary-dark" style={{transform: "rotate(15deg)"}} icon={faEnvelopeOpen} size="2x"/>
                                </div>
                                <h6 className="mb-0">aminjeetent@gmail.com</h6>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" style={{height: "500px"}}>
                            <div className="position-relative h-100">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3669.633558839636!2d72.5839364!3d23.1105064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e839043f46f65%3A0xdb3baaebde80a971!2sSAFALYA%20Hospital%20-%20Dr.%20Jeet%20Amin!5e0!3m2!1sen!2sin!4v1705465133948!5m2!1sen!2sin" width="100%" height="450" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center position-relative" style={{marginTop: '-200px', zIndex: '1'}}>
                        <div className="col-lg-8">
                            <div className="bg-white rounded p-lg-5 m-lg-5 p-3 m-3 mb-0">
                                <form ref={form} onSubmit={sendEmail}>
                                    <div className="row g-3">
                                        <div className="col-12 col-sm-6">
                                            <input id="c_name" name="name" type="text" className="form-control bg-primary-light border-0" placeholder="Your Name" style={{height: "55px"}}/>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <input id="c_email" name="email" type="email" className="form-control bg-primary-light border-0" placeholder="Your Email" style={{height: "55px"}}/>
                                        </div>
                                        <div className="col-12">
                                            <input id="c_subject" name="subject" type="text" className="form-control bg-primary-light border-0" placeholder="Subject" style={{height: "55px"}}/>
                                        </div>
                                        <div className="col-12">
                                            <textarea id="c_message" name="message" className="form-control bg-primary-light border-0" rows="5" placeholder="Message"></textarea>
                                        </div>
                                        <div className="col-12">
                                            <button id="send_message_btn" className="btn bg-primary-dark border-primary-dark btn-secondary w-100 py-3" type="submit">{isLoading?"Processing...":"Send Message"}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />                   
            <Whatsappflot/>
            <EventFAB/>
            <Footer/>
        </main>
    )
}