import 'react-toastify/dist/ReactToastify.css';
import Topbar from '../components/Topbar.jsx'
import SafalyaNavbar from '../components/SafalyaNavbar.jsx'
import Whatsappflot from '../components/Whatsappflot.jsx'
import EventFAB from '../components/EventFAB.jsx'
import Footer from '../components/Footer.jsx'
import { useLocation } from 'react-router-dom';

export default function ThankU() {
    const location = useLocation();
    const data = location.state;
    
    return (
        <main>
            <Topbar/>
            <SafalyaNavbar/>
            <div className='mt-5 text-align-center'>
                <h2 className='color-primary-dark '>
                    {data.text}
                </h2>
                <h4 className='color-secondary-dark'>
                    Our team will contact you shortly
                </h4>
            </div>  
            <Whatsappflot/> 
            <EventFAB/>           
            <Footer/>
        </main>
    )
}