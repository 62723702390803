import Topbar from '../components/Topbar.jsx'
import SafalyaNavbar from '../components/SafalyaNavbar.jsx'
import Whatsappflot from '../components/Whatsappflot.jsx'
import Footer from '../components/Footer.jsx'
import EventCard from '../components/EventCard.jsx'

export default function Events() {    
    return (
        <main>
            <Topbar/>
            <SafalyaNavbar/>  
            <Whatsappflot/> 
            <img src={require('../events/posters/04022024_01.jpg')}
            style={{
                display: "block",
                maxWidth: "80%",
                height: "auto",
                marginLeft: "auto",
                marginRight: "auto"                                               
            }}
            />
            <Footer/>
        </main>
    )
}