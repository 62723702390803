import "../styles/style.css"
import "../styles/bootstrap.min.css"
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { NavDropdown } from "react-bootstrap";

export default function SafalyaNavbar() {
    return (
        <div className="sticky-top" >	
            <div className="container-fluid bg-white shadow-sm">
                <div className="container" >                    
                    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
                        <Container>
                            <Navbar.Brand className="navbar-brand" href="/">
                                <img className="navbar-brand-image" src={require("../img/LOGO.png")}/>        					
                            </Navbar.Brand>
                            <Navbar.Toggle className="no-border" aria-controls="responsive-navbar-nav" />
                            <Navbar.Collapse id="responsive-navbar-nav">
                                <Nav className="me-auto">
                                    <Nav.Link href="/">Home</Nav.Link>
                                    <NavDropdown title="Services" id="basic-nav-dropdown">
                                        <NavDropdown.Item href="/services/ENT">ENT</NavDropdown.Item>                                        
                                        <NavDropdown.Item href="/services/dental">Dental Care</NavDropdown.Item>  
                                    </NavDropdown>  
                                    <Nav.Link href="/events">Events</Nav.Link>
                                    <Nav.Link href="/blogs">Blogs</Nav.Link>
                                    <Nav.Link href="/contact">Contact</Nav.Link>                                                                    
                                </Nav>
                            </Navbar.Collapse>
                            <a className="btn bg-primary-dark no-border btn-secondary ms-4 d-none d-lg-block" href="/appointment" role="button" style={{fontWeight: "bold"}}>Book Appointment</a>
                        </Container>
                    </Navbar>            
                </div>
            </div>
            <div className="container-fluid px-0">
                <a className="btn bg-primary-dark no-border btn-secondary mx-0 d-sm-block d-lg-none" style={{zIndex: "2",width: '100%', borderRadius: '0', fontWeight: "bold"}} href="/appointment" role="button">Book Appointment</a>            
            </div>
    	</div>
    )
}