import 'react-toastify/dist/ReactToastify.css';
import { useRef, useState } from 'react'
import Topbar from '../components/Topbar.jsx'
import SafalyaNavbar from '../components/SafalyaNavbar.jsx'
import Whatsappflot from '../components/Whatsappflot.jsx'
import EventFAB from '../components/EventFAB.jsx'
import Footer from '../components/Footer.jsx'
import { ToastContainer, toast } from 'react-toastify';
import emailjs from '@emailjs/browser';
import { useNavigate } from "react-router-dom";

export default function Home() {
    const form = useRef();
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();

    // Validation Logic begin

    const validateForm = () => {
        const f = form.current;
        let hasError = true;
        let errors = []

        // const mobileNoRegex = /^\+?[1-9]\d{1,14}$/;
        // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if(f.elements.name.value == ""){
            hasError = false;    
            errors.push("Please provide patient's name");  
        }
        if(f.elements.email.value== ""){
            hasError = false;
            errors.push("Please provide valid email address");  
        }
        if(f.elements.mobile_no.value==""){
            hasError = false;        
            errors.push("Please provide valid mobile number");  
        }

        if(errors.length>0){
            let errorString = "";
            errors.forEach(element => {
                errorString += '\n# ' + element
            });
            alert(errorString);
        }                

        return hasError;        
    };

    // Validation Logic end

    const sendEmail = (e) => {
        e.preventDefault(); // prevents the page from reloading when you hit “Send”

        if(validateForm()){
            setLoading(true);            

            emailjs.sendForm('service_0rtylpb', 'template_prpuuyb', form.current, 'j-WhXUVtZVvN66K6Y')
                .then((result) => {
                    form.current.reset();
                    setLoading(false);
                    navigate("/appointment/thankU", {state: {text: "Your appointment has been booked successfully!"}})
                }, (error) => {
                    setLoading(false);
                    // toast.error(error.text);
                    alert("Something went wrong, try after some time or use WhatsApp appointment");
                });
        }        
    };

    return (
        <main>
            <Topbar />
            <SafalyaNavbar />
            <Whatsappflot />
            <EventFAB />
            <div id="appointment" className="container-fluid bg-primary-light my-5 py-5">
                <div className="container py-5">
                    <div className="row gx-5">
                        <div className="col-lg-6 mb-5 mb-lg-0">
                            <div className="mb-4">
                                <h3 className="d-inline-block color-secondary-dark text-uppercase border-5">Appointment</h3>
                                <h2 className="display-4 color-primary-dark">Make An Appointment For Yourself and Your Family</h2>
                            </div>
                            <p className="color-secondary-dark mb-5">A perfect choice for people having busy schedules or living in a remote areas</p>
                        </div>
                        <div className="col-lg-6">
                            <div className="bg-white text-center rounded p-5">
                                <h2 className="mb-4 color-primary-dark" >Book An Appointment</h2>
                                <form ref={form} onSubmit={sendEmail}>
                                    <div className="row g-3">
                                        <div className="col-12 col-sm-12">
                                            <input id="p_name" name="name" type="text" className="form-control bg-primary-light border-0" placeholder="Your Name"
                                                style={{ height: "55px" }} />
                                        </div>
                                        <div className="col-12 col-sm-12">
                                            <input id="p_problem" name="problem" type="text" className="form-control bg-primary-light border-0" placeholder="Your Problem in one line"
                                                style={{ height: "55px" }} />
                                        </div>
                                        <div className="col-12 col-sm-12 text-align-left">
                                            <input id="p_email" name="email" type="email" className="form-control bg-primary-light border-0" placeholder="Your Email"
                                                style={{ height: "55px" }}/>
                                        </div>
                                        <div className="col-12 col-sm-12">
                                            <input id="p_mobile_no" name="mobile_no" type="tel" className="form-control bg-primary-light border-0" placeholder="Your Mobile No"
                                                style={{ height: "55px" }} pattern="[0-9]{10}" />
                                        </div>
                                        <div className="col-12 col-sm-12">
                                            <input id="p_city" name="city" type="text" className="form-control bg-primary-light border-0" placeholder="Your City"
                                                style={{ height: "55px" }} />
                                        </div>
                                        <div className="col-12 col-sm-12">
                                            <div className="date" id="date" data-target-input="nearest">
                                                <input id="p_date" name="date" type="text" className="form-control bg-primary-light border-0 datetimepicker-input"
                                                    placeholder="Date in dd/mm/yyyy format" data-target="#date" data-toggle="datetimepicker"
                                                    style={{ height: "55px" }} />
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <button id="bookAppointmentBtn" disabled={isLoading} type="submit" className="btn bg-primary-dark text-white w-100 py-3">{isLoading ? "Processing..." : "Make An Appointment"}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <Footer />
        </main>
    )
}