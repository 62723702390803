import "../styles/style.css"
import "../styles/bootstrap.min.css"
import { Rating } from 'react-simple-star-rating'
import Carousel from "react-bootstrap/Carousel";


export default function Testimonial() {

    return (        
        <div>
            <div className="pt-5 text-center mx-auto mb-5" style={{maxWidth: "500px"}}>
                <h3 className="d-inline-block color-secondary-dark text-uppercase border-5">Testimonials</h3>
                <h2 className="display-4" style={{color: 'var(--primary-dark)'}}>Patients Say About Us</h2>
            </div>          
            <Carousel className="bg-primary-light testimonial-courosal" interval={2000}>                
                <Carousel.Item className="bg-primary-light">
                    <div className="testimonial-courosal-item bg-primary-light">
                        <div className="testimonial-courosal-image bg-secondary-dark text-white">
                            K
                        </div>
                        <div className="testimonial-courosal-content">
                            <p className="testimonial-courosal-content-name">
                                Krinesh Patel
                            </p>  
                            <div className="testimonial-courosal-content-rating">
                                <Rating  initialValue="5" size="25"/>
                            </div>
                            <div className="testimonial-courosal-content-text">
                                <p style={{textAlign: "center"}}>
                                    Exceptional skills displayed by Dr. Jeet amin ,an ENT surgeon, left a lasting 
                                    impression. Patients praised thorough consultations, precise procedures, and 
                                    compassionate care. His expertise and commitment make him a trusted choice for ear, 
                                    nose, and throat issues. Highly recommended.
                                </p>
                            </div>
                        </div>
                    </div>                    
                </Carousel.Item>
                <Carousel.Item className="bg-primary-light">
                    <div className="testimonial-courosal-item bg-primary-light">
                        <div className="testimonial-courosal-image bg-secondary-dark text-white">
                            M
                        </div>
                        <div className="testimonial-courosal-content">
                            <p className="testimonial-courosal-content-name">
                                Mukesh Gupta
                            </p>  
                            <div className="testimonial-courosal-content-rating">
                                <Rating  initialValue="5" size="25"/>
                            </div>
                            <div className="testimonial-courosal-content-text">
                                <p style={{textAlign: "center"}}>
                                    Doctor Jeet is one of the most caring doctors I have had the pleasure of having. 
                                    His staff is no different and will go the length to make sure all is well.
                                    He does not rush you in and out, expect a wait because he takes time with his 
                                    patients and actually listens. Seriously not a typical doctor, he is a good man.
                                </p>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item className="bg-primary-light">
                    <div className="testimonial-courosal-item bg-primary-light">
                        <div className="testimonial-courosal-image bg-secondary-dark text-white">
                            D
                        </div>
                        <div className="testimonial-courosal-content">
                            <p className="testimonial-courosal-content-name">
                                Devam Bhatt
                            </p>  
                            <div className="testimonial-courosal-content-rating">
                                <Rating  initialValue="5" size="25"/>
                            </div>
                            <div className="testimonial-courosal-content-text">
                                <p style={{textAlign: "center"}}>
                                    I have seen Dr. Jeet amin for a year now at other clinic. 
                                    I won't think of seeing another ENT. I have recommended him to my friends and family numerous times. 
                                    If you need to see someone for ear ,nose and throat issues this is the guy. 
                                    Friendly experienced and overall a great doctor.
                                </p>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item className="bg-primary-light">
                    <div className="testimonial-courosal-item bg-primary-light">
                        <div className="testimonial-courosal-image bg-secondary-dark text-white">
                            D
                        </div>
                        <div className="testimonial-courosal-content">
                            <p className="testimonial-courosal-content-name">
                                Divyam Goel
                            </p>  
                            <div className="testimonial-courosal-content-rating">
                                <Rating  initialValue="5" size="25"/>
                            </div>
                            <div className="testimonial-courosal-content-text">
                                <p style={{textAlign: "center"}}>
                                    Patient and understanding. Knowledgeable and skillful.
                                    I found him to be a humble, dedicated and trustworthy person. 
                                    Listened to all issues. Response to his treatment is magical.
                                    Highly recommended!
                                </p>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>                
            </Carousel>
        </div>           
    )
}