import "../styles/style.css"
import "../styles/bootstrap.min.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faYoutube, faLinkedinIn, faFacebookF } from '@fortawesome/free-brands-svg-icons';

export default function Topbar() {
    return (
        <div className="container-fluid py-2 bg-secondary-dark border-bottom d-none d-lg-block d-sm-block">
            <div className="container">
                <div className="row">
                    <div className="col-md-6 text-center text-lg-start mb-2 mb-lg-0">
                        <div className="d-inline-flex align-items-center">
                            <a className="color-primary-light pe-3" href=""><i className="bi bi-telephone me-2"></i>+91
                                8849363677</a>
                            <span className="color-primary-light">|</span>
                            <a className="color-primary-light px-3" href=""><i
                                    className="bi bi-envelope me-2"></i>aminjeetent@gmail.com</a>
                        </div>
                    </div>
                    <div className="col-md-6 text-center text-lg-end">
                        <div className="d-inline-flex align-items-center">
                            <a className="text-body px-2" href="https://www.facebook.com/profile.php?id=100092376169840&mibextid=LQQJ4d">
                                <FontAwesomeIcon className="color-primary-dark" icon={faFacebookF}/>
                            </a>
                            <a className="text-body px-2" href="">
                                <FontAwesomeIcon className="color-primary-dark" icon={faLinkedinIn}/>
                            </a>
                            <a className="text-body px-2" href="https://www.instagram.com/dr.aj_entskullbase?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
                                <FontAwesomeIcon className="color-primary-dark" icon={faInstagram}/>
                            </a>
                            <a className="text-body ps-2" href="https://youtube.com/@jeetaminentskullbase">
                                <FontAwesomeIcon className="color-primary-dark" icon={faYoutube}/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}