import "../styles/style.css"
import "../styles/bootstrap.min.css"
import "./Home.css"
import Topbar from '../components/Topbar.jsx'
import SafalyaNavbar from '../components/SafalyaNavbar.jsx'
import Whatsappflot from '../components/Whatsappflot.jsx'
import Services from '../components/Services.jsx'
import Footer from '../components/Footer.jsx'
import About from '../components/About.jsx'
import EventFAB from '../components/EventFAB.jsx'
import Testimonial from '../components/Testimonial.jsx'
import Carousel from "react-bootstrap/Carousel";

export default function Home() {    
    return (
        <main>
            <Topbar/>
            <SafalyaNavbar/>  
            <Whatsappflot/> 
            <EventFAB/>
            <div className="container-fluid">  
                <Carousel interval={2000} nextIcon={<span/>} prevIcon={<span/>}>
                    <Carousel.Item>      
                        <div className="home-hero">
                            <div className="home-hero-content">   
                                <p className="text-white">
                                    WELCOME TO
                                </p>                                                                        
                                <h1 className="text-white">
                                    SAFALYA HOSPITAL
                                </h1>                                                        
                                <p className="mt-2 text-white">
                                    Safalya Hospital provides quality care and treatment with state-of-the-art facilities, 
                                    experienced professionals, and a patient-centric approach.
                                </p>                                                   
                            </div>   
                        </div>                                                                               
                    </Carousel.Item>
                </Carousel>                  
            </div>
            <About/>
            <Services/>
            <Testimonial/>
            <Footer/>
        </main>
    )
}