import 'react-toastify/dist/ReactToastify.css';
import Topbar from '../../components/Topbar.jsx'
import SafalyaNavbar from '../../components/SafalyaNavbar.jsx'
import Whatsappflot from '../../components/Whatsappflot.jsx'
import EventFAB from '../../components/EventFAB.jsx'
import Footer from '../../components/Footer.jsx'

export default function Dental() {
    
    return (
        <main>
            <Topbar/>
            <SafalyaNavbar/>
            <div className='m-4 p-3 shadow-box'>
                <h3 className='color-primary-dark '>
                    About our Dental department
                </h3>
                <p>Welcome to the Dental Department at Our Hospital, where we offer a full range of dental services to meet the needs of patients of all ages. Our dedicated team of experienced dentists, dental hygienists, and support staff are committed to providing the highest quality of care in a comfortable and friendly environment.</p>
            </div>
            <div className='m-4 p-3 shadow-box'>
                <h3 className='color-primary-dark '>
                    Our Services
                </h3>
                <p>At Our Hospital, we understand the importance of maintaining good oral health and its impact on overall well-being. Our Dental Department provides a comprehensive suite of services, including:</p>
                <ul>
                    <li><p>Preventive Care:</p> <p>Regular check-ups, cleanings, and fluoride treatments to prevent dental issues and promote oral hygiene.</p></li>
                    <li><p>Restorative Dentistry:</p> <p>Treatments for damaged or decayed teeth, including fillings, crowns, bridges, and root canal therapy.</p></li>
                    <li><p>Cosmetic Dentistry: </p><p>Enhancing the appearance of your smile with teeth whitening, veneers, and orthodontics.</p></li>
                    <li><p>Pediatric Dentistry:</p> <p>Specialized care for children's dental needs, focusing on preventive measures and early detection of dental issues.</p></li>
                    <li><p>Oral Surgery:</p><p> Procedures such as extractions, wisdom teeth removal, and dental implants, performed by our skilled oral surgeons.</p></li>
                    <li><p>Periodontal Care:</p><p> Treatment and management of gum disease, including scaling, root planing, and periodontal surgery.</p></li>
                    <li><p>Emergency Dental Services:</p> <p>Prompt care for dental emergencies, including toothaches, broken teeth, and other urgent issues.</p></li>
                </ul>
            </div>
            <div className='m-4 p-3 shadow-box'>
                <h3 className='color-primary-dark '>
                    Advanced Technology and Techniques
                </h3>
                <p>Our Dental Department is equipped with state-of-the-art technology to ensure accurate diagnosis and effective treatment. We use digital X-rays, intraoral cameras, and other modern tools to provide the best possible care. Our commitment to staying at the forefront of dental advancements ensures that our patients receive the most up-to-date treatments available.</p>
            </div>     
            <div className='m-4 p-3 shadow-box'>
                <h3 className='color-primary-dark '>
                    Patient-Centered Care
                </h3>
                <p>We believe in a patient-centered approach to dental care. Our team takes the time to listen to your concerns, explain treatment options, and develop personalized care plans that meet your specific needs. We strive to make every visit as comfortable and stress-free as possible, offering sedation options for those with dental anxiety.</p>
            </div>     
            <div className='m-4 p-3 shadow-box'>
                <h3 className='color-primary-dark '>
                    Your Smile, Our Priority
                </h3>
                <p>At Our Hospital, your oral health is our top priority. Whether you're visiting for a routine check-up, a cosmetic procedure, or a dental emergency, you can trust our team to provide exceptional care. We are dedicated to helping you achieve and maintain a healthy, beautiful smile.</p>
            </div>
            <div className='m-4 p-3 shadow-box'>
                <h3 className='color-primary-dark '>
                    Schedule Your Appointment Today
                </h3>
                <p>Take the first step towards optimal oral health by scheduling an appointment with our Dental Department. Contact us at +91 8849363677 or email us at aminjeetent@gmail.com. We look forward to serving you and your family's dental needs.</p>
            </div>     
            <Whatsappflot/> 
            <EventFAB/>           
            <Footer/>
        </main>
    )
}