import { useRef, useState } from 'react'

export default function Test() {
    const form = useRef();
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");

    const handleEmailChange = (event) => {
        const value = event.target.value;
        setEmail(value);

        if (!value.includes("@")) {
            setEmailError("Invalid email format");
        } else {
            setEmailError("");
        }
    };

    return (
        <form ref={form}>
            <div className="row g-3">
                {/* <div className="col-12 col-sm-12">
                    <input id="p_name" name="name" type="text" className="form-control bg-primary-light border-0" placeholder="Your Name"
                        style={{ height: "55px" }} />
                </div>
                <div className="col-12 col-sm-12">
                    <input id="p_problem" name="problem" type="text" className="form-control bg-primary-light border-0" placeholder="Your Problem in one line"
                        style={{ height: "55px" }} />
                </div> */}
                <div className="col-12 col-sm-12">
                    <input id="p_email" name="email" type="email" className="form-control bg-primary-light border-0" placeholder="Your Email"
                        style={{ height: "55px" }} value={email} onChange={handleEmailChange} />
                    {emailError && <p>{emailError}</p>}
                </div>
                {/* <div className="col-12 col-sm-12">
                    <input id="p_mobile_no" name="mobile_no" type="tel" className="form-control bg-primary-light border-0" placeholder="Your Mobile No"
                        style={{ height: "55px" }} pattern="[0-9]{10}" />
                </div>
                <div className="col-12 col-sm-12">
                    <input id="p_city" name="city" type="text" className="form-control bg-primary-light border-0" placeholder="Your City"
                        style={{ height: "55px" }} />
                </div>
                <div className="col-12 col-sm-12">
                    <div className="date" id="date" data-target-input="nearest">
                        <input id="p_date" name="date" type="text" className="form-control bg-primary-light border-0 datetimepicker-input"
                            placeholder="Date in dd/mm/yyyy format" data-target="#date" data-toggle="datetimepicker"
                            style={{ height: "55px" }} />
                    </div>
                </div>
                <div className="col-12">
                    <button id="bookAppointmentBtn" disabled={isLoading} type="submit" className="btn bg-primary-dark text-white w-100 py-3">{isLoading ? "Processing..." : "Make An Appointment"}</button>
                </div> */}
            </div>
        </form>
    );
}