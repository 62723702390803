import "../styles/style.css"
import "../styles/bootstrap.min.css"
import { Button, Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const readMore = event => {
    let button = document.getElementById(event.currentTarget.id);    
    if (button.innerText == "Read More") {        
        button.innerText  = "Read Less";
        let textBox = document.getElementById(event.currentTarget.id+"-text-box");
        textBox.style.display = "block";
    } else {        
        button.innerText  = "Read More";
        let textBox = document.getElementById(event.currentTarget.id+"-text-box");
        textBox.style.display = "none";
    }
};

export default function Services() {
    const navigate = useNavigate();

    const moveToENT = () => navigate("/services/ENT");

    return (
        <div id="services" className="container-fluid">
            <div className="text-center mx-auto mb-5" style={{maxWidth: "500px"}}>
                <h3 className="d-inline-block color-secondary-dark text-uppercase border-5">Services</h3>
                <h2 className="display-4" style={{color: 'var(--primary-dark)'}}>Excellent Medical Services</h2>
            </div>
            <Row xs={1} md={2} lg={4} style={{marginLeft: "5%", marginRight: "5%"}}>      
                <Col style={{marginBottom: "20px"}}>
                    <div style={{height: "400px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}} onClick={moveToENT}>
                        <img src={require("../img/ear-1.jpeg")} style={{width: "100%", height: "90%",objectFit: "fill"}}/>
                        <div className="bg-primary-light" style={{display: "grid", width: "100%", height: "10%", padding: "10px", textAlign: "center"}}>
                            <p className="color-secondary-dark" style={{ textAlign: "center" ,fontFamily: "'Montserrat', sans-serif"}}>EAR</p>
                            {/* <p className="services-description" style={{ color: "#000000", overflow: "scroll"}}>
                                Treatment of ear diseases including: 
                                Otitis Externa,
                                Otomycosis,
                                Osteoma Of Ear Canal,
                                Ear Drum Perforation,
                                Acute Otitis Media,
                                Serous Otitis Media,
                                Eustachian Tube Dysfunction,
                                Cholestetoma,
                                Otosclerosis,
                                Ossicular Disruption,
                                Temporal Bone Fracture,
                                Carcinoma of Temporal Bone,
                                Middle Ear Implant and
                                Cochlear Implant for Deaf-Mute.
                            </p>
                            <Button className="text-white bg-primary-dark border-primary-dark" href="#" style={{}}>More</Button> */}
                        </div>                        
                    </div>
                </Col>
                <Col style={{marginBottom: "20px"}}>
                    <div style={{height: "400px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}} onClick={moveToENT}>
                        <img src={require("../img/nose.jpeg")} style={{width: "100%", height: "90%",objectFit: "fill"}}/>
                        <div className="bg-primary-light" style={{display: "grid", width: "100%", height: "10%", padding: "10px", textAlign: "center"}}>
                            <p className="color-secondary-dark" style={{ textAlign: "center" ,fontFamily: "'Montserrat', sans-serif"}}>NOSE</p>
                            {/* <p className="services-description" style={{ color: "#000000", overflow: "scroll"}}>
                                Treatment of nose diseases including: 
                                Vestibulitis,
                                Rhinitis,
                                Allergic Rhinitis,
                                Nose Polyps,
                                Deviated Septum (DNS),
                                Mucourmycosis,
                                Dentigerous cyst,
                                Dacryocystitis,
                                Epistaxis (Nasal Bleed),
                                Inverted Papilloma,
                                Fungal Sinusitis and
                                Pot's Puffy Tumour
                            </p>
                            <Button className="text-white bg-primary-dark border-primary-dark" href="#" style={{}}>More</Button> */}
                        </div>                        
                    </div>
                </Col>
                <Col style={{marginBottom: "20px"}}>
                    <div style={{height: "400px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}} onClick={moveToENT}>
                        <img src={require("../img/nack.jpeg")} style={{width: "100%", height: "90%",objectFit: "fill"}}/>
                        <div className="bg-primary-light" style={{display: "grid", width: "100%", height: "10%", padding: "10px", textAlign: "center"}}>
                            <p className="color-secondary-dark" style={{ textAlign: "center" ,fontFamily: "'Montserrat', sans-serif"}}>HEAD AND NECK</p>
                            {/* <p className="services-description" style={{ color: "#000000", overflow: "scroll"}}>
                                Treatment of head neck diseases including: 
                                Parotid Gland Tumours,
                                SubMandibular Gland Tumours,
                                All types Carcinoma of Head Neck,
                                Nose Carcinoma,
                                Thyroid Tumours,
                                Parathyroid Tumours,
                                Lipomas of Neck,
                                Ludwig’s Angina,
                                Thyroglosal Cysts and
                                Foreign Body In Throat
                            </p>
                            <Button className="text-white bg-primary-dark border-primary-dark" href="#" style={{}}>More</Button> */}
                        </div>                        
                    </div>
                </Col> 
                <Col style={{marginBottom: "20px"}}>
                    <div style={{height: "400px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}} onClick={moveToENT}>
                        <img src={require("../img/skull-base.jpeg")} style={{width: "100%", height: "90%",objectFit: "fill"}}/>
                        <div className="bg-primary-light" style={{display: "grid", width: "100%", height: "10%", padding: "10px", textAlign: "center"}}>
                            <p className="color-secondary-dark" style={{ textAlign: "center" ,fontFamily: "'Montserrat', sans-serif"}}>SKULL BASE</p>
                            {/* <p className="services-description" style={{ color: "#000000", overflow: "scroll"}}>
                                Treatment of skull base diseases including: 
                                Pituitary Adenoma,
                                Craniopharyngioma,
                                Tuberculum Meningioma,
                                Angiofibroma,
                                Extended Intracranial Fungal Sinusitis,
                                Optic Glioma,
                                Fibrous Dysplasia of Skull Base,
                                Skull Base Osteomyelitis,
                                Glomus Jugulare (Paraganglioma),
                                Glomus Mastoidalis,
                                Vagal Paraganglioma,
                                Carotid Body Tumours,
                                Acoustic Neuroma (Vestibular Schwannoma),
                                Petrous Apex Cholestetoma,
                                Congenital Cholestetoma,
                                CSF Leak,
                                Vestibular Neurectomy,
                                CP Angle Tumours,
                                Petroclival Tumours,
                                Trigeminal Neuralgia,
                                Hemifacial Spasm,
                                Glossopharyngeal Neuralgia,
                                Chondroma and
                                Chondrosarcoma.
                            </p>
                            <Button className="text-white bg-primary-dark border-primary-dark" href="#" style={{}}>More</Button> */}
                        </div>                        
                    </div>
                </Col>
                <Col style={{marginBottom: "20px"}}>
                    <div style={{height: "400px", boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}} onClick={()=>navigate("/services/dental")}>
                        <img src={require("../img/dental_service.jpeg")} style={{width: "100%", height: "90%",objectFit: "fill"}}/>
                        <div className="bg-primary-light" style={{display: "grid", width: "100%", height: "10%", padding: "10px", textAlign: "center"}}>
                            <p className="color-secondary-dark" style={{ textAlign: "center" ,fontFamily: "'Montserrat', sans-serif"}}>Dental Care</p>                            
                        </div>                        
                    </div>
                </Col>   
            </Row>
            {/* <div className="container">
                <div className="text-center mx-auto mb-5" style={{maxWidth: "500px"}}>
                    <h5 className="d-inline-block color-secondary-dark text-uppercase border-5">Services</h5>
                    <h1 className="display-4" style={{color: 'var(--primary-dark)'}}>Excellent Medical Services</h1>
                </div>
                <div className="row g-5">
                    <div className="col-lg-4 col-md-6">
                        <div 
                            className="service-item bg-primary-light rounded d-flex flex-column align-items-center justify-content-center py-2">
                            <div className="service-icon mb-4 mt-2">
                                <img src={require("../img/brain.png")} style={{transform: "rotate(+14deg)"}} height="50%"/>
                            </div>
                            <h4 className="mb-3 color-primary-dark" >Treatment of Skull Base Diseases
                            </h4>
                            <p className="m-0">
                                <ul>
                                    <li>Pituitary Adenoma</li>
                                    <li>Craniopharyngioma</li>
                                    <li>Tuberculum Meningioma</li>
                                    <li>Angiofibroma</li>
                                    <li>Extended Intracranial Fungal Sinusitis</li>  
                                    <li>Optic Glioma</li>
                                    <li>Fibrous Dysplasia of Skull Base</li> 
                                    <li>Skull Base Osteomyelitis</li>
                                    <li>Glomus Jugulare (Paraganglioma)</li>
                                    <li>Glomus Mastoidalis</li>                                                                
                                    <div>
                                        <span id="service-1-text-box" style={{display: "none"}}>                                                                            
                                            <li>Vagal Paraganglioma </li>
                                            <li>Carotid Body Tumours</li>
                                            <li>Acoustic Neuroma (Vestibular Schwannoma)</li>
                                            <li>Petrous Apex Cholestetoma </li>
                                            <li>Congenital Cholestetoma </li>
                                            <li>CSF Leak</li>
                                            <li>Vestibular Neurectomy</li>
                                            <li>CP Angle Tumours</li>
                                            <li>Petroclival Tumours</li>
                                            <li>Trigeminal Neuralgia</li>
                                            <li>Hemifacial Spasm</li>
                                            <li>Glossopharyngeal Neuralgia</li>
                                            <li>Chondroma</li>
                                            <li>Chondrosarcoma</li>
                                        </span>
                                    </div>                                                          
                                </ul>                                                     
                            </p>
                            <button className="btn bg-primary-dark no-border border-primary-dark btn-secondary" onClick={readMore} id="service-1">Read More</button>                        
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="service-item bg-primary-light rounded d-flex flex-column align-items-center justify-content-center py-2">
                            <div className="service-icon mb-4 mt-2">
                                <img src={require("../img/listen.png")} style={{transform: "rotate(+14deg)"}} height="50%"/>
                            </div>
                            <h4 className="mb-3 color-primary-dark">Treatment of EAR Diseases
                            </h4>
                            <p className="m-0">
                                <ul>
                                    <li>Otitis Externa</li>
                                    <li>Otomycosis</li>
                                    <li>Osteoma Of Ear Canal</li>
                                    <li>Ear Drum Perforation</li>
                                    <li>Acute Otitis Media</li>
                                    <li>Serous Otitis Media</li>
                                    <li>Eustachian Tube Dysfunction</li>
                                    <li>Cholestetoma </li>
                                    <li>Otosclerosis </li>
                                    <li>Ossicular Disruption</li>
                                    <div>
                                        <span id="service-2-text-box" style={{display: "none"}}>  
                                            <li>Temporal Bone Fracture</li>
                                            <li>Carcinoma of Temporal Bone</li>
                                            <li>Middle Ear Implant</li>
                                            <li>Cochlear Implant for Deaf-Mute</li>
                                        </span>
                                    </div>                            
                                </ul>
                            </p>   
                            <button className="btn bg-primary-dark no-border border-primary-dark btn-secondary" onClick={readMore} id="service-2">Read More</button>                   
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="service-item bg-primary-light rounded d-flex flex-column align-items-center justify-content-center py-2">
                            <div className="service-icon mb-4 mt-2">
                                <img src={require("../img/nose.png")} style={{transform: "rotate(+14deg)"}} height="50%"/>
                            </div>
                            <h4 className="mb-3 color-primary-dark">Treatment Of Nose Diseases 
                            </h4>
                            <p className="m-0">
                                <ul>
                                    <li>Vestibulitis </li>
                                    <li>Rhinitis</li>
                                    <li>Allergic Rhinitis </li>
                                    <li>Nose Polyps</li>
                                    <li>Deviated Septum (DNS)</li>
                                    <li>Mucourmycosis</li>
                                    <li>Dentigerous cyst</li>
                                    <li>Dacryocystitis</li>
                                    <li>Epistaxis (Nasal Bleed)</li>
                                    <li>Inverted Papilloma</li>
                                    <div>
                                        <span id="service-3-text-box" style={{display: "none"}}>  
                                            <li>Fungal Sinusitis</li>
                                            <li>Pot's Puffy Tumour</li>
                                        </span>
                                    </div>
                                    
                                </ul>                            
                            </p>    
                            <button className="btn bg-primary-dark no-border border-primary-dark btn-secondary" onClick={readMore} id="service-3">Read More</button>                    
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="service-item bg-primary-light rounded d-flex flex-column align-items-center justify-content-center py-2">
                            <div className="service-icon mb-4 mt-2">
                                <img src={require("../img/throat.png")} style={{transform: "rotate(+14deg)"}} height="50%"/>
                            </div>
                            <h4 className="mb-3 color-primary-dark">Treatment of Throat & Oral Cavity Diseases</h4>
                            <p className="m-0">
                                <ul>
                                    <li>Aphthous Ulcer</li>
                                    <li>Stomatitis</li>
                                    <li>Oral Mucositis</li>
                                    <li>Tonsillitis </li>
                                    <li>Adenoids</li>
                                    <li>Tonsiloliths</li>
                                    <li>Mucous Retention Cyst</li>
                                    <li>Pharyngitis </li>
                                    <li>Fracture Of Mandible </li>
                                    <li>Laryngitis</li>
                                    <div>
                                        <span id="service-4-text-box" style={{display: "none"}}>  
                                            <li>Vocal Cord Polyps</li>
                                            <li>Vocal Cord Nodules</li>
                                            <li>Vocal Cord Palsy</li>
                                            <li>Vallecular Cyst</li>
                                            <li>Salivary Glad Stones</li>
                                        </span>
                                    </div>                                
                                </ul>                            
                            </p> 
                            <button className="btn bg-primary-dark no-border border-primary-dark btn-secondary" onClick={readMore} id="service-4">Read More</button>                       
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div
                            className="service-item bg-primary-light rounded d-flex flex-column align-items-center justify-content-center py-2">
                            <div className="service-icon mb-4 mt-2">
                                <img src={require("../img/neck.png")} style={{transform: "rotate(+14deg)"}} height="50%"/>
                            </div>
                            <h4 className="mb-3 color-primary-dark">Treatment of Head Neck Diseases</h4>
                            <p className="m-0">
                                <ul>
                                    <li>Parotid Gland Tumours </li>
                                    <li>SubMandibular Gland Tumours</li>
                                    <li>All types Carcinoma of Head Neck</li>
                                    <li>Nose Carcinoma</li>
                                    <li>Thyroid Tumours</li>
                                    <li>Parathyroid Tumours</li>
                                    <li>Lipomas of Neck</li>
                                    <li>Ludwig’s Angina</li>
                                    <li>Thyroglosal Cysts</li>
                                    <li>Foreign Body In Throat</li>
                                    <div>
                                        <span id="service-5-text-box" style={{display: "none"}}>  
                                            <li>Foreign Body Bronchus</li>
                                            <li>Foreign Body Oesophagus</li>
                                            <li>Plumber Vinson Syndrome</li>
                                        </span>
                                    </div>                                
                                </ul>                            
                            </p>  
                            <button className="btn bg-primary-dark no-border border-primary-dark btn-secondary" onClick={readMore} id="service-5">Read More</button>                      
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}