import "../styles/style.css"
import "../styles/bootstrap.min.css"
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';

export default function EventCard() {
    return (
        <Row xs={1} md={3} sm={2} style={{margin: "10px"}}>
            <Col >
              <Card style={{marginTop: "10px"}}>
                <Card.Img variant="top" src={require('../img/medical-checkup.jpg')} />
                {/* <Card.ImgOverlay>                                   
                  <div style={{position: "absolute", top: "0", left: "0", width:"50%"}}>
                    <img style={{width: "100%"}} src={require('../img/COMING_SOON_STRIP.png')}/>                    
                  </div>                  
                </Card.ImgOverlay> */}
                <Card.Body className="bg-primary-light">
                  <Card.Title>Free medical check-up</Card.Title>
                  <Card.Text>
                    Join us for a free medical checkup event where our team of experienced healthcare 
                    professionals will provide complimentary health screenings, consultations, and resources. 
                    Don't miss this chance to prioritize your health and well-being!
                  </Card.Text>
                </Card.Body>
                <ListGroup className="list-group-flush">
                    <ListGroup.Item className="bg-primary-light"><span style={{fontWeight: "bold"}}>Date: </span>4-2-2024, 10 AM (Morning)</ListGroup.Item>                    
                    <ListGroup.Item className="bg-primary-light"><span style={{fontWeight: "bold"}}>Location: </span>senior citizen park, beside Safalya hospital, near Shivshaktinagar bus stop, Chandkheda, Ahmedabad</ListGroup.Item>                    
                </ListGroup>
                <Card.Body className="bg-primary-light" style={{textAlign: "center"}}>
                    <Card.Link className="color-primary-dark border-bottom-primary border-2" href="/events/E04022024_01" style={{position: "relative", fontWeight: "bold"}}>More</Card.Link>                    
                </Card.Body>
              </Card>
            </Col>             
        </Row>        
    )
}