import "../styles/style.css"
import "../styles/bootstrap.min.css"

export default function Whatsappflot() {
    return (
        <div className="container-fluid py-5">
            <div className="container">
                <div className="row gx-5">
                    <div className="col-lg-5 mb-5 mb-lg-0 about-photo-div" style={{minHeight: "500px"}}>
                        <div className="position-relative h-100 w-100" style={{display: "flex", justifyContent: "center"}}>
                            <img className="position-absolute about-photo-img h-100" src={require("../img/about.jpg")} style={{boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"}}/>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="mb-4">
                            <h3 className="d-inline-block text-uppercase color-secondary-dark border-5">About Founder</h3>
                            <h2 className="display-4 color-primary-dark" >Dr. Jeet Amin</h2>
                        </div>
                        <p>He is an ENT and skull base surgeon. He did his MBBS from prestigious B.J. Medical College, Ahmedabad. 
                            Then he did master of surgery in ENT and head and neck surgery from PDU medical college and hospital, Rajkot. 
                            After his post gragutation, he went to Milan, Italy for fellowship of middle ear, auditory implant and lateral, skull base surgery. 
                            Then he opted fellowship of endoscopic transnasal skull base surgery at Nanavati max super speciality sospital, Bombay under the guidance of Dr. Narayan Jayashakar.                         
                        </p>
                        <p>
                            He has proficiency in neurotology and lateral skull base surgery as well as endoscopic sinus &
                            skull base surgery. He has keen interest in patient of facial palsy, sinus allergy, vertigo & thyroid diseases.
                            He is performing all types of surgeries of ear, nose, head-neck and skull base.
                        </p>                            
                    </div>
                </div>
            </div>
        </div>
    )
}