import 'react-toastify/dist/ReactToastify.css';
import Topbar from '../../components/Topbar.jsx'
import SafalyaNavbar from '../../components/SafalyaNavbar.jsx'
import Whatsappflot from '../../components/Whatsappflot.jsx'
import EventFAB from '../../components/EventFAB.jsx'
import Footer from '../../components/Footer.jsx'
import './services.css'

export default function ENT() {

    return (
        <main>
            <Topbar />
            <SafalyaNavbar />
            <div className='m-4 p-3 shadow-box'>
                <h3 className='color-primary-dark '>
                    About Our ENT Department
                </h3>
                <p>At Our Hospital, our ENT department specializes in diagnosing and treating a wide range of conditions affecting the ear, nose, and throat. Our team of experienced ENT specialists provides comprehensive care using the latest medical advancements and technologies.</p>
            </div>
            <div className='m-4 p-3 shadow-box'>
                <h3 className='color-primary-dark '>
                    Our Services
                </h3>
                <p>Our ENT Department offers a broad spectrum of services designed to address various disorders and improve your quality of life. Our services include:</p>
                <ul>
                    <li><p>Ear Care:</p> <p>Diagnosis and treatment of conditions such as ear infections, hearing loss, tinnitus, and balance disorders. We provide advanced solutions, including hearing aids and surgical interventions if necessary.</p></li>
                    <li><p>Nasal and Sinus Care:</p> <p>Management of sinusitis, allergic rhinitis, nasal polyps, and deviated septum. We offer both medical and surgical treatments to alleviate symptoms and restore nasal function.</p></li>
                    <li><p>Throat Care:</p> <p>Evaluation and treatment for tonsillitis, voice disorders, swallowing difficulties, and throat cancer. Our comprehensive approach includes both non-surgical and surgical options.</p></li>
                    <li><p>Pediatric ENT Care:</p> <p>Specialized care for children’s ENT needs, focusing on early detection and treatment of conditions such as ear infections and sleep apnea.</p></li>
                    <li><p>Allergy Testing and Treatment:</p><p> Comprehensive allergy testing and tailored treatment plans to manage allergic conditions affecting the ear, nose, and throat.</p></li>
                    <li><p>Sleep Disorders:</p><p>Diagnosis and treatment of sleep-related disorders, including snoring and obstructive sleep apnea, to improve your overall sleep quality.</p></li>                    
                </ul>
            </div>
            <div className='m-4 p-3 shadow-box'>
                <h3 className='color-primary-dark '>
                Cutting-Edge Technology
                </h3>
                <p>Our ENT Department utilizes the latest medical technology to provide accurate diagnoses and effective treatments. We use advanced imaging techniques, endoscopic equipment, and other modern tools to ensure the best outcomes for our patients.</p>
            </div>     
            <div className='m-4 p-3 shadow-box'>
                <h3 className='color-primary-dark '>
                Patient-Focused Approach
                </h3>
                <p>We are committed to providing a patient-centered approach to care. Our team takes the time to listen to your concerns, discuss treatment options in detail, and develop personalized care plans that address your specific needs. We strive to ensure that every visit is comfortable and informative, offering support and guidance throughout your treatment journey.</p>
            </div>     
            <div className='m-4 p-3 shadow-box'>
                <h3 className='color-primary-dark '>
                Your Well-being, Our Commitment
                </h3>
                <p>At Our Hospital, we understand the impact that ENT disorders can have on your overall health and well-being. Our goal is to provide you with the highest quality care and help you achieve optimal health. Whether you're seeking treatment for a chronic condition or an acute issue, our specialists are here to support you every step of the way.</p>
            </div>
            <div className='m-4 p-3 shadow-box'>
                <h3 className='color-primary-dark '>
                Schedule Your Consultation
                </h3>
                <p>To learn more about our ENT services or to schedule an appointment, please contact our department at +91 8849363677 or email us at aminjeetent@gmail.com. We look forward to providing you with exceptional care and helping you on your path to better health.</p>
            </div>
            <Whatsappflot />
            <EventFAB />
            <Footer />
        </main>
    )
}