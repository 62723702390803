import "../styles/style.css"
import "../styles/bootstrap.min.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

export default function EventFAB() {
    return (
        <div className="events-floating-btn">
            <div style={{
                width: "25px",
                height: "25px",
                backgroundColor: "#ff0000",
                borderRadius: "50%",                
                position: "absolute",
                left: "0%",
                top: "0%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#ffffff", 
                fontWeight: "bold",
            }}>0</div>   
            <a href="/events">
                <div className="bg-secondary-dark" 
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Upcoming events"                
                    style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%", 
                    justifyContent: "center", 
                    display: "flex",
                    alignItems: "center",
                }}>   
                    <Tooltip id="my-tooltip" className="bg-primary-dark" style={{fontWeight: "bold"}}/>                             
                    <FontAwesomeIcon className="text-white" icon={faCalendar}/>				    			
                </div>
            </a>                                           
        </div>
    )
}