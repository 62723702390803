import "../styles/style.css"
import "../styles/bootstrap.min.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhoneAlt, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faFacebookF, faLinkedinIn, faYoutube } from '@fortawesome/free-brands-svg-icons';

export default function Footer() {
    return (
        <div className="container-fluid bg-footer text-light mt-5 py-5">
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-lg-3 col-md-6">
                        <h3 className="d-inline-block text-white text-uppercase border-5 border-primary-dark mb-4">Get In Touch</h3>
                        <p className="mb-4 text-white">We care for your senses</p>                        
                        <p className="mb-2 text-white"><FontAwesomeIcon className="me-3 text-white" icon={faEnvelope}/>aminjeetent@gmail.com</p>
                        <p className="mb-0 text-white"><FontAwesomeIcon className="me-3 text-white" icon={faPhoneAlt}/>+91-8849363677</p>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h3 className="d-inline-block text-white text-uppercase border-5 border-primary-dark mb-4">Quick Links</h3>
                        <div className="d-flex flex-column justify-content-start">
                            <a className="text-white mb-2" href="/"><FontAwesomeIcon className="me-2 text-white" icon={faAngleRight}/>Home</a>                            
                            <a className="text-white mb-2" href="/events"><FontAwesomeIcon className="me-2 text-white" icon={faAngleRight}/>Events</a>  
                            <a className="text-white" href="/contact"><FontAwesomeIcon className="me-2 text-white" icon={faAngleRight}/>Contact Us</a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h3 className="d-inline-block text-white text-uppercase border-5    border-primary-dark mb-4">Popular Links</h3>
                        <div className="d-flex flex-column justify-content-start">
                            <a className="text-white mb-2" href="/"><FontAwesomeIcon className="me-2 text-white" icon={faAngleRight}/>Home</a> 
                            <a className="text-white mb-2" href="/events"><FontAwesomeIcon className="me-2 text-white" icon={faAngleRight}/>Events</a>                             
                            <a className="text-white" href="/contact"><FontAwesomeIcon className="me-2 text-white" icon={faAngleRight}/>Contact Us</a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <h3 className="text-white text-uppercase mt-4 mb-3">Follow Us</h3>
                        <div className="d-flex">                            
                            <a className="border-white px-3 rounded-circle" style={{width: "50px", height: "50px", border: "solid", alignItems: "center", display: "flex", justifyContent: "center" }} href="https://www.facebook.com/profile.php?id=100092376169840&mibextid=LQQJ4d"><FontAwesomeIcon className="color-secondary-dark" icon={faFacebookF}/></a>
                            <a className="border-white rounded-circle px-3" style={{width: "50px", height: "50px", border: "solid", alignItems: "center", display: "flex", justifyContent: "center" }} href="#"><FontAwesomeIcon className="color-secondary-dark" icon={faLinkedinIn}/></a>
                            <a className="border-white rounded-circle px-3" style={{width: "50px", height: "50px", border: "solid", alignItems: "center", display: "flex", justifyContent: "center" }} href="https://youtube.com/@jeetaminentskullbase"><FontAwesomeIcon className="color-secondary-dark" icon={faYoutube}/></a>
                            <a className="border-white rounded-circle px-3" style={{width: "50px", height: "50px", border: "solid", alignItems: "center", display: "flex", justifyContent: "center" }} href="https://www.instagram.com/dr.aj_entskullbase?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="><FontAwesomeIcon className="color-secondary-dark" icon={faInstagram}/></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}